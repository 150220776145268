@import "@/assets/scss/main.scss";

.option {
    position: relative;
}

.empfohlen {
    position: absolute;
    inset: 0;
    height: 8px;
    border-top: 8px solid $elsi-color-lightblue;
    border-radius: 6px 6px 0 0;

    &::before {
        content: 'empfohlen';
        color: $elsi-color-lightblue;
        position: absolute;
        top: -28px;
        right: 0;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
    }
}

.title {
    color: $elsi-color-lightblue;
}

section#tool-sections {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    //padding: 8px 40px calc(32px - 1rem) 40px;
    padding-top: 2rem;

    h5 {
        font-style: bold;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .tool-sections {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1.5rem;
    }
}
